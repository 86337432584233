import { useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  Container, Row, Col, Image,
} from 'react-bootstrap';

import TallyForm from '../../components/TallyForm';

import logoWkcWhite from '../../assets/img/logo-wkc-white.webp';

import './styles.scss';

const TestSyndromeImposteur = ({ anonymous }) => {
  const tallyLink = useMemo(() => (anonymous
    ? 'https://tally.so/embed/w8JPDO?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1'
    : 'https://tally.so/embed/mBQNve?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1'), [anonymous]);

  return (
    <div className="test-si">
      <header className="test-si__header bg-wkc-violet py-4">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={6}>
              <h1 className="text-white text-center fs-3">
                Test du Syndrome de l'Imposteur
              </h1>
              <h3 className="text-white text-center">
                By
                <Image
                  className="logo"
                  src={logoWkcWhite}
                  alt="Logo Workin'Cool"
                  fluid
                  loading="lazy"
                  decoding="async"
                />
              </h3>
            </Col>
          </Row>
        </Container>
      </header>
      <section className="section-tally py-5">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={8}>
              <TallyForm link={tallyLink} />
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

TestSyndromeImposteur.propTypes = {
  anonymous: PropTypes.bool.isRequired,
};

export default TestSyndromeImposteur;
